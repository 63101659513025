<script>
import { mapState } from "vuex";
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import Matriz from "@/components/widgets/network-matriz";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
    Matriz,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      loading: false,
      matriz: null,
    };
  },
  methods: {
    getMatriz() {
      this.loading = true;

      api.get("network/matriz").then((response) => {
        if (response.data.status == "success") {
          this.matriz = response.data.matriz;
        } else {
          this.matriz = null;
        }

        this.loading = false;
      });
    },
  },
  mounted() {
    this.getMatriz();
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item">Rede</li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>{{ t('Matriz') }}</h3>
        <p></p>
      </div>
    </div>


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <Matriz :data="matriz"></Matriz>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
